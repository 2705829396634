import './App.css'

import StartpageBody from "./components/StartpageBody"


function App() {

    return (
        <div className="App">
            <StartpageBody />
        </div>
    )
}

export default App;
